import WebFont from 'webfontloader';
import 'katex/dist/katex.css';

WebFont.load({
  google: {
    families: ['Roboto:400,400i,500,700'],
  },
});

export function onRouteUpdate() {
  const target = document.querySelector(':target');
  if (target) {
    const rect = target.getBoundingClientRect();
    const el = document.scrollingElement;
    el.scrollTop = el.scrollTop + rect.top - 60;
  }
}
